import React from "react";

import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import Form from "@ROM-ui/Form";
import Button from "@ROM-ui/Button";
import ShadowContainer from "@ROM-ui/ShadowContainer";

import PropTypes from "prop-types";

const Filters = ({ handleFilterChange, handleClearFilters, filters }) => {
  return (
    <ShadowContainer className="mt-2 mb-4">
      <Row className="py-2 d-flex flex-wrap align-items-center">
        <Col>
          <Form.Control
            type="text"
            placeholder="Search by name"
            value={filters.name}
            name="name"
            onChange={(e) => handleFilterChange("name", e.target.value)}
          />
        </Col>
        <Col>
          <Button className="p-0" size="sm" variant="link" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </Col>
      </Row>
    </ShadowContainer>
  );
};

Filters.propTypes = {
  handleFilterChange: PropTypes.func,
  handleClearFilters: PropTypes.func,
  filters: PropTypes.object,
};

export default Filters;
