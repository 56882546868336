import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { canUpdateCustomersAllParams } from "@ROM/Customers/permissions";
import Badge, { ActiveBadge } from "@ROM-ui/Badge";
import PriceListDropDown from "./PriceListDropDown";

const CustomerRow = ({ customer, priceList, showPriceList, onCustomerEdit }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <tr className="cursor-pointer" id={`edit-customer-${customer.id}`}>
      <td onClick={() => onCustomerEdit(customer.id)}>{customer.attributes.name}</td>
      
      <td onClick={() => onCustomerEdit(customer.id)}>{customer.attributes.invoiceNumberPrefix}</td>
      {/* {showPriceList && <td>{priceList?.attributes?.name || "N/A"}</td>} */}
      {showPriceList && (
        <td>
          <PriceListDropDown customer={customer} priceList={priceList} />
        </td>
      )}
      <td>
        {canUpdateCustomersAllParams(currentUser) && (
          <>
            <ActiveBadge isActive={customer.attributes.isActive} className="me-2" />
            {customer.attributes.taxExempt && (
              <Badge bg="primary" className="me-2">
                TAX EXEMPT
              </Badge>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

CustomerRow.propTypes = {
  customer: PropTypes.shape().isRequired,
  priceList: PropTypes.shape,
  showPriceList: PropTypes.bool.isRequired,
  onCustomerEdit: PropTypes.func.isRequired,
};

CustomerRow.defaultProps = {
  priceList: null,
};

export default CustomerRow;