import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import AsyncPaginate from "@ROM-components/common/AsyncPaginate";
import { loadOptions as loadPriceListsOptions } from "@ROM/PriceLists/utils";
import { update } from "@ROM/Customers/actions";
import { selectAllCustomerCropAdvisorUsers } from "@ROM/Users/selectors";
import { fetchCustomer } from "@ROM/Customers/actions";

const unstyledSelectorStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    border: "none",
    minWidth: '200px'
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
    width: '100%',
    minWidth: '200px'
  }),
  menuList: (provided) => ({
    ...provided,
    width: '100%'
  })
};

const PriceListDropDown = ({ customer, priceList }) => {
  const dispatch = useDispatch();
  const customerCropAdvisors = useSelector(selectAllCustomerCropAdvisorUsers);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ type: null });

  useEffect(() => {
    if (priceList) {
      setSelectedOption({
        value: priceList?.id,
        label: priceList?.attributes?.name
      });
    }
  }, [priceList]);

  const handleChange = (newValue) => {
    if (!customer?.id) return;

    setLoading(true);
    setFeedback({ type: null });
    setSelectedOption(newValue);

    const updatePayload = {
      name: customer.attributes.name,
      company_id: customer.attributes.companyId,
      invoice_number_prefix: customer.attributes.invoiceNumberPrefix,
      is_active: customer.attributes.isActive,
      tax_exempt: customer.attributes.taxExempt,
      primary_contact: customer.attributes.primaryContact,
      region_id: customer.attributes.regionId,
      needs_approval: customer.attributes.needsApproval,
      stripe_id: customer.attributes.stripeId,
      price_list_id: newValue ? newValue.value : null,
      crop_advisors: customerCropAdvisors.map(advisor => advisor.id)
    };

    dispatch(update(customer.id, updatePayload))
    .then((response) => {
      if (response.error) {
        setFeedback({ type: 'error' });
        setLoading(false);
        setSelectedOption(null);
      } else {
        dispatch(fetchCustomer(customer.id)); // Add this line
        setLoading(false);
        setFeedback({ type: 'success' });
        setTimeout(() => setFeedback({ type: null }), 2000);
      }
    })
  };

  return (
    <div className="position-relative">
      <AsyncPaginate
        value={selectedOption}
        loadOptions={loadPriceListsOptions}
        onChange={handleChange}
        placeholder="Select Price List"
        additional={{ page: 1 }}
        isDisabled={loading}
        styles={unstyledSelectorStyles}
        cacheUniqs={[customer.id]}
      />

      {feedback.type && (
        <div 
          className="position-absolute" 
          style={{ 
            right: -25,
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        >
          {feedback.type === 'success' && (
            <i className="fas fa-check text-success" />
          )}
          {feedback.type === 'error' && (
            <i className="fas fa-times text-danger" />
          )}
        </div>
      )}
    </div>
  );
};

PriceListDropDown.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      companyId: PropTypes.number,
      invoiceNumberPrefix: PropTypes.string,
      isActive: PropTypes.bool,
      taxExempt: PropTypes.bool,
      primaryContact: PropTypes.string,
      regionId: PropTypes.number,
      needsApproval: PropTypes.bool,
      stripeId: PropTypes.string
    }).isRequired
  }).isRequired,
  priceList: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string
    })
  })
};

PriceListDropDown.defaultProps = {
  priceList: null
};

export default PriceListDropDown;