import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormater from "@ROM-ui/NumberFormater";
import PointerSpan from "@ROM-ui/PointerSpan";
import PropTypes from "prop-types";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { canSeeExtraInformation, canSeeOrdersFromAllRegions, canUpdateOrder } from "@ROM/Orders/permissions";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { selectOrdersCustomers } from "@ROM/Orders/selectors";
import OrderStatusBadge from "@ROM/OrderStatuses/components/OrderStatusBadge";
import { orderUrl, orderEditUrl } from "@ROM-utils/urlHelpers";
import PdfActions from "@ROM/Orders/components/PdfActions";
import OrderDeliveryListRow from "@ROM/Orders/components/List/OrderDeliveryListRow";
import { canSeeOrderDeliveries } from "@ROM/Deliveries/permissions";
import deliveriesApi from "@ROM/Deliveries/api";

const OrderListRow = ({ order }) => {
  const ordersCustomers = useSelector(selectOrdersCustomers);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);

  const { attributes } = order;
  const customer = ordersCustomers.find((item) => item.id === order.relationships.customer.data.id);

  const createdAt = new Date(attributes.createdAt);
  const history = useHistory();
  const [error, setError] = useState(false);
  const [showDeliveries, setShowDeliveries] = useState(false);
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);
  const [deliveries, setDeliveries] = useState(null);
  const [deliveriesItems, setDeliveriesItems] = useState(null);
  const [deliveriesWarehouses, setDeliveriesWarehouses] = useState(null);
  const deliveriesCount = order.relationships.deliveries.data.length;
  const showDeliveriesToggle = canSeeOrderDeliveries(currentUser, order);

  const editOrder = (ev, orderId) => {
    ev.stopPropagation();
    history.push(orderEditUrl(orderId));
  };

  const toggleShowDeliveries = (event) => {
    if (deliveriesCount === 0) return;

    event.stopPropagation();
    setShowDeliveries(!showDeliveries);

    if (!deliveriesItems) {
      setLoadingDeliveries(true);

      deliveriesApi.list({ orderId: order.id }).then((response) => {
        setDeliveries(response.data.data);
        setDeliveriesItems(response.data.included.filter((item) => item.type === "deliveryItem"));
        setDeliveriesWarehouses(response.data.included.filter((item) => item.type === "warehouse"));
        setTimeout(() => setLoadingDeliveries(false), 600);
      });
    }
  };

  const permissions = React.useMemo(() => {
    if (!customer) return {
      canSeeRegions: false,
      canSeeExtra: false,
      canUpdate: false,
      spanCols: 7,
      deliverStr: ''
    };

    const canSeeRegions = canSeeOrdersFromAllRegions(currentUser, customer.id);
    const canSeeExtra = canSeeExtraInformation(currentUser);

    return {
      canSeeRegions,
      canSeeExtra, 
      canUpdate: canUpdateOrder(currentUser, order, currentCompany),
      spanCols: 7 + (canSeeRegions ? 1 : 0) + (canSeeExtra ? 2 : 0),
      deliverStr: `${deliveriesCount} ${deliveriesCount === 1 ? 'delivery' : 'deliveries'}`
    };
  }, [customer, currentUser, order, currentCompany, deliveriesCount]);

  const { canSeeRegions, canSeeExtra, canUpdate, spanCols, deliverStr } = permissions;

  return (
    <>
      <tr onClick={() => history.push(orderUrl(order.id))} className="text-nowrap fw-bold cursor-pointer">
        <StyledTd className="text-capitalize rounded-start ps-3" id={`order-index-status-badge-${order.id}`}>
          <OrderStatusBadge status={attributes.status} />
        </StyledTd>
        {canSeeRegions && <StyledTd>{order.attributes.regionName}</StyledTd>}
        {canSeeExtra && <StyledTd>{customer.attributes.name}</StyledTd>}
        <StyledTd>{attributes.invoiceNumber}</StyledTd>
        <StyledTd>{attributes.poNumber}</StyledTd>
        <StyledTd>{createdAt.toLocaleDateString()}</StyledTd>
        {canSeeExtra && <StyledTd>{order.attributes.creator}</StyledTd>}
        <StyledTd className="text-center">{attributes.orderItemsCount}</StyledTd>
        <StyledTd className="text-end">
          <NumberFormater value={attributes.totalCents} />
        </StyledTd>
        <StyledTd className="d-flex align-items-center">
          <div
            role="button"
            tabIndex={0}
            onClick={showDeliveriesToggle && toggleShowDeliveries}
            onKeyPress={showDeliveriesToggle && toggleShowDeliveries}
          >
            {deliverStr}
            {showDeliveriesToggle && deliveriesCount > 0 && (
              <i className={`ms-1 fa ${showDeliveries ? "fa-sort-up pt-1" : "fa-sort-down pb-1"}`} />
            )}
          </div>
        </StyledTd>
        <StyledTd className="rounded-end">
          <div className="d-flex">
            {canUpdate && (
              <PointerSpan
                onClick={(ev) => editOrder(ev, order.id)}
                className="text-primary fas fa-edit me-xl-2 fa-fw mt-1"
                title="Edit"
              />
            )}
            <PdfActions orderId={order.id} setError={setError} />
          </div>
        </StyledTd>
      </tr>
      {error && (
        <tr>
          <StyledTd colSpan={spanCols} className="text-danger fw-bold mt-2 text-end">
            Something went wrong, please try again later.
          </StyledTd>
        </tr>
      )}
      {showDeliveries &&
        (loadingDeliveries ? (
          <tr>
            <td colSpan={spanCols}>
              <Skeleton count={deliveriesCount} height={30} />
            </td>
          </tr>
        ) : (
          deliveries.map((delivery) => (
            <tr key={delivery.id}>
              <td colSpan={spanCols}>
                <OrderDeliveryListRow
                  delivery={delivery}
                  deliveriesItems={deliveriesItems}
                  deliveriesWarehouses={deliveriesWarehouses}
                  orderId={order.id}
                />
              </td>
            </tr>
          ))
        ))}
    </>
  );
};

OrderListRow.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    attributes: {
      status: PropTypes.string,
      createdAt: PropTypes.string,
      regionName: PropTypes.string,
      creator: PropTypes.string,
      invoiceNumber: PropTypes.string,
      poNumber: PropTypes.string,
      totalCents: PropTypes.number,
      orderItemsCount: PropTypes.number,
    },
    relationships: {
      customer: { data: { id: PropTypes.number } },
      deliveries: { data: PropTypes.arrayOf() },
    },
  }).isRequired,
};

const StyledTd = styled.td`
  vertical-align: middle !important;
  background-color: #f5f5f5 !important;
  height: 50px;
`;

export default OrderListRow;
