import * as JsPDF from "jspdf";
import "jspdf-autotable";

import { buildHeader, buildAdresses } from "@ROM/App/utils/pdf";
import { cleanDecimals, numberFormat } from "@ROM/App/utils/format";
import { canCancelOrders, canCancelUnapprovedOrders } from "@ROM/OrderStatuses/permissions";

export const createPdf = async (data, included, company, toPrint, includeNotes) => {
  const { attributes, relationships } = data;

  const cropAdvisorId = relationships.cropAdvisor?.data?.id;

  const customer = included.find((incl) => incl.type === "customer");
  const orderItems = included.filter((incl) => incl.type === "orderItem");
  const cropAdvisor = included.find((item) => item.type === "user" && item.id === cropAdvisorId);
  const billingAddress = included.find((incl) => incl.type === "billingAddress");
  const shippingAddress =
    attributes.shippingAddressId === attributes.billingAddressId
      ? billingAddress
      : included.find((incl) => incl.type === "shippingAddress");

  const numberWithDecimals = (number) => (number / 100).toFixed(2);
  const formatNumber = (number, unit = null) => `${numberFormat(numberWithDecimals(number))}${unit ? `/${unit}` : ""}`;

  const poNumber = `${data.attributes.poNumber}`;

  const dateFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  const shippingDate = company.attributes.showOrderShipDate
    ? dateFormat(new Date(data.attributes.shipDate))
    : dateFormat(new Date(data.attributes.createdAt));

  const showDiscountColumn = orderItems.some((item) => item.attributes.discountAmount !== 0);
  const formatDiscountAmount = (discountCents, type, discount) => {
    const formatTotal = `$${formatNumber(discountCents)}`;
    const lineContent = type === "percent" ? `${cleanDecimals(discount / 100.0)}% [${formatTotal}]` : formatTotal;
    return lineContent;
  };

  let orderItemsColumnStyles = {
    0: { halign: "left" },
    1: { halign: "left" },
    3: { halign: "center" },
    4: { halign: "right" },
    5: { halign: "right" },
  };
  const orderItemsHeader = [["ITEM #", "DESCRIPTION", "QTY", "# PALLETS", "UNIT PRICE", "AMOUNT"]];
  const orderItemsBody = orderItems.map((item) => {
    const { attributes } = item;
    const notes = attributes.notes && includeNotes ? `\nNotes: ${attributes.notes}` : "";
    const itemNumber = attributes.itemNumber || "";
    const desc = `${attributes.productName || ""}${notes}`;
    const qty = attributes.quantity || 0;
    const pallets = qty / attributes.productPackagesPerPallet;
    const unitPrice = `$${formatNumber(attributes.productPriceCents, attributes.unit) || 0}`;
    const discount =
      attributes.discountCents !== 0.0
        ? formatDiscountAmount(attributes.discountCents, attributes.discountType, attributes.discountAmount)
        : "";
    const total = attributes.isTrial ? "[TRIAL] $0.00" : `$${formatNumber(attributes.finalPriceCents)}`;
    return showDiscountColumn ? [itemNumber, desc, qty, pallets, unitPrice, discount, total] : [itemNumber, desc, qty, pallets, unitPrice, total];
  });
  if (showDiscountColumn) {
    orderItemsColumnStyles = {
      ...orderItemsColumnStyles,
      5: { halign: "right" },
      6: { halign: "right" },
    };
    orderItemsHeader[0].splice(4, 0, "DISCOUNT");
  }
  console.log(orderItemsBody)
  const pdf = new JsPDF({
    orientation: "p", 
    unit: "mm", 
    format: "letter",
    compress: true,
    putOnlyUsedFonts: true
  });
  pdf.setFont("helvetica", "normal", "normal");
  pdf.rect(6, 6, 203, 266);

  const currentDatetime = new Date();
  const formattedDate = `${currentDatetime.getMonth() + 1}/${currentDatetime.getDate()}/${currentDatetime.getFullYear()}`;

  pdf.setFontSize(9);
  await buildHeader(pdf, "Sales Order", company, customer, attributes, formattedDate);

  const lastY = await buildAdresses(pdf, billingAddress, shippingAddress);

  pdf.autoTable({
    theme: "grid",
    styles: { cellPadding: 1 },
    headStyles: { halign: "center", fillColor: [199, 107, 31], textColor: [0, 0, 0], fontSize: 7 },
    bodyStyles: { fontSize: 7, textColor: [0, 0, 0], halign: "center" },
    startY: lastY + 5,
    head: [["REFERENCE #", "PAYMENT TERMS", "DUE DATE"]],
    body: [[poNumber, "Net 30", shippingDate]],
  });

  pdf.autoTable({
    theme: "grid",
    styles: { cellPadding: 1 },
    headStyles: { halign: "center", fillColor: [199, 107, 31], textColor: [0, 0, 0], fontSize: 7 },
    bodyStyles: { fontSize: 7, textColor: [0, 0, 0], halign: "center" },
    columnStyles: orderItemsColumnStyles,
    startY: pdf.autoTable.previous.finalY + 5,
    head: orderItemsHeader,
    body: orderItemsBody,
  });

  const taxExempt = customer?.attributes?.taxExempt ?? false;

  const summaryItems = [
    ["SUBTOTAL", `$${formatNumber(attributes.subtotalCents)}`],
    ["TAX", `${taxExempt ? "Exempt" : `$${formatNumber(attributes.taxCents)}`}`],
    ["TOTAL", `$${formatNumber(attributes.totalCents)}`],
  ];

  if (attributes.discountAmount !== 0.0) {
    summaryItems.splice(1, 0, [
      "ORDER DISCOUNT",
      formatDiscountAmount(attributes.discountCents, attributes.discountType, attributes.discountAmount),
    ]);
  }

  const itemsTotalDiscount = orderItems.reduce((acc, item) => acc + item.attributes.discountCents, 0);
  if (itemsTotalDiscount !== 0.0) summaryItems.splice(1, 0, ["ITEMS DISCOUNT", `$${formatNumber(itemsTotalDiscount)}`]);

  pdf.autoTable({
    theme: "grid",
    styles: {
      fontSize: 7,
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "right",
      cellWidth: 30,
      cellPadding: 1,
    },
    startY: pdf.autoTable.previous.finalY + 5,
    margin: { left: 142 },
    body: summaryItems,
    compress: true,
  });

  if (cropAdvisor) {
    pdf.autoTable({
      theme: "plain",
      startY: pdf.autoTable.previous.finalY + 8,
      styles: { halign: "right" },
      headStyles: { fontStyle: "bold", cellPadding: { top: 0, right: 0, bottom: 2, left: 0 } },
      bodyStyles: { cellPadding: { top: 0, right: 0, bottom: 1, left: 0 } },
      head: [["Crop Advisor"]],
      body: [[cropAdvisor.attributes.fullName], [cropAdvisor.attributes.email]],
    });
  }

  if (includeNotes && attributes.notes) {
    pdf.autoTable({
      theme: "plain",
      startY: pdf.autoTable.previous.finalY + 5,
      body: [["Notes:", attributes.notes]],
    });
  }

  const pageCount = pdf.internal.getNumberOfPages();
  pdf.setPage(pageCount);
  pdf.text("THANK YOU FOR YOUR BUSINESS", 80, 260);
  pdf.text("NOT FOR PAYMENT", 92, 265);

  if (toPrint) {
    pdf.autoPrint();
    // This won't work if the user has an ad blocker
    window.open(pdf.output("bloburl"));
  } else {
    pdf.save(`Sales Order - ${attributes.invoiceNumber}.pdf`);
  }
};

export const createInventoryPdf = async (data, included, company, toPrint) => {
  const { attributes } = data;

  const customer = included.find((incl) => incl.type === "customer");
  const orderItems = included.filter((incl) => incl.type === "orderItem");

  const numberWithDecimals = (number) => (number / 100).toFixed(2);
  const formatNumber = (number, unit = null) => `${numberFormat(numberWithDecimals(number))}${unit ? `/${unit}` : ""}`;

  const showDiscountColumn = orderItems.some((item) => item.attributes.discountAmount !== 0);
  const formatDiscountAmount = (discountCents, type, discount) => {
    const formatTotal = `$${formatNumber(discountCents)}`;
    const lineContent = type === "percent" ? `${cleanDecimals(discount / 100.0)}% [${formatTotal}]` : formatTotal;
    return lineContent;
  };

  let orderItemsColumnStyles = {
    0: { halign: "left" },
    1: { halign: "left" },
    3: { halign: "center" },
    4: { halign: "right" },
    5: { halign: "right" },
  };
  const orderItemsHeader = [["DESCRIPTION", "QTY", "# PALLETS"]];
  const orderItemsBody = orderItems.map((item) => {
    const { attributes } = item;

    const desc = `${attributes.productName}`;
    const qty = `${attributes.quantity || 0}/${attributes.productUnitsPerPackage} ${attributes.unit}. ${attributes.packageUnit}`;
    const pallets = (attributes.quantity / attributes.productPackagesPerPallet).toFixed(1).replace(/\.0$/, '')
    const unitPrice = `$${formatNumber(attributes.productPriceCents, attributes.unit) || 0}`;
    const discount =
      attributes.discountCents !== 0.0
        ? formatDiscountAmount(attributes.discountCents, attributes.discountType, attributes.discountAmount)
        : "";
    const total = attributes.isTrial ? "[TRIAL] $0.00" : `$${formatNumber(attributes.finalPriceCents)}`;
    return showDiscountColumn ? [desc, qty, pallets, unitPrice, discount, total] : [desc, qty, pallets, unitPrice, total];
  });
  if (showDiscountColumn) {
    orderItemsColumnStyles = {
      ...orderItemsColumnStyles,
      5: { halign: "right" },
      6: { halign: "right" },
    };
    orderItemsHeader[0].splice(4, 0, "DISCOUNT");
  }
  console.log(orderItemsBody)
  const pdf = new JsPDF({
    orientation: "p", 
    unit: "mm", 
    format: "letter",
    compress: true,
    putOnlyUsedFonts: true
  });
  pdf.setFont("helvetica", "normal", "normal");
  pdf.rect(6, 6, 203, 266);

  const currentDatetime = new Date();
  const formattedDate = `${currentDatetime.getMonth() + 1}/${currentDatetime.getDate()}/${currentDatetime.getFullYear()}`;

  pdf.setFontSize(9);
  const startY = await buildHeader(pdf, "Inventory List", company, customer, attributes, formattedDate);

  pdf.autoTable({
    theme: "grid",
    styles: { cellPadding: 1 },
    headStyles: { halign: "center", fillColor: [199, 107, 31], textColor: [0, 0, 0], fontSize: 7 },
    bodyStyles: { fontSize: 7, textColor: [0, 0, 0], halign: "center" },
    columnStyles: orderItemsColumnStyles,
    startY: startY + 15,
    head: orderItemsHeader,
    body: orderItemsBody,
    compress: true,
  });

  const pageCount = pdf.internal.getNumberOfPages();
  pdf.setPage(pageCount);

  if (toPrint) {
    pdf.autoPrint();
    // This won't work if the user has an ad blocker
    window.open(pdf.output("bloburl"));
  } else {
    pdf.save(`Inventory List - ${attributes.invoiceNumber}.pdf`);
  }

}

export const isOwner = (order, user) => user.attributes?.ordersIds?.some((id) => id === order.id);

export const isOpened = (order) => order.attributes?.status === "opened";

export const isInCart = (order) => order.attributes?.status === "cart";

export const isApproved = (order) => order.attributes?.status === "approved";

export const isCancelled = (order) => order.attributes?.status === "cancelled";

export const isConfirmed = (order) => order.attributes?.status === "confirmed";

export const isInProgress = (order) => order.attributes?.status === "in_progress";

export const isShipped = (order) => order.attributes?.status === "shipped";

export const isInvoiced = (order) => order.attributes?.status === "invoiced";

export const isCompleted = (order) => order.attributes?.status === "completed";

export const showDeliveries = (order) =>
  isInProgress(order) || isShipped(order) || isInvoiced(order) || isCompleted(order) || isCancelled(order);

export const canBeCharged = (order) =>
  (isInProgress(order) || isShipped(order)) && order.attributes.paymentMethodIsCard && !order.attributes.hasBeenPaid;

export const showPayments = (order) => (isInProgress(order) || isShipped(order)) && order.attributes.paymentMethodIsCard;

export const canBeCanceled = (order, user, customerId) =>
  ((isInCart(order) || isOpened(order)) && canCancelUnapprovedOrders(user, customerId)) ||
  ((isInCart(order) || isOpened(order) || isApproved(order) || isConfirmed(order) || isInProgress(order)) &&
    canCancelOrders(user, customerId));

export const estimateOrderPallets = (orderItems) =>
  orderItems.reduce(
    (acc, { attributes: { productPackagesPerPallet, quantity } }) =>
      acc + (productPackagesPerPallet ? quantity / productPackagesPerPallet : 0),
    0
  );
